import React from 'react'
import styles from './styles.module.scss'

type LoaderProps = {
  size?: "small" | "medium" | "large"
}

const Loader = (props: LoaderProps) => {
  return (
    <div className={[styles.wrapper, props.size && styles[props.size]].filter(Boolean).join(' ')}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

export default Loader
