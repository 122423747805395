import { useState, useEffect, useContext } from 'react'
import styles from './styles.module.scss'
import ApaleoService from 'services/ApaleoService'
import POSGroup from './POSGroup'
import SkeletonTransaction from './Transaction/Skeleton'
import dayjs from 'dayjs'
import { AuthContext } from 'context'
import Box from 'components/Box'

const Properties = () => {
  const { property } = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState<string[]>([])
  const [transactions, setTransactions] = useState<any>([])

  useEffect(() => {
    if (property) {
      const fetchTransactions = async () => {
        setErrors([])
        setLoading(true)
        const from = dayjs().subtract(1, 'w').toISOString()
        const to = dayjs().toISOString()
        const { data: transactions } = await ApaleoService.getTransactions(
          property,
          'TransitoryItems',
          from,
          to
        )

        if (!transactions.transactions) {
          setErrors(['Keine Transaktionen gefunden'])
          setLoading(false)
          return
        }

        const filteredTransactions = transactions.transactions.filter(
          (item: any) => item.receipt.number.slice(0, 2) === 'RG' && item.command === 'PostCharge'
        )

        const groupedFilteredTransactions = filteredTransactions.reduce(
          (fields: any, item: any) => {
            const field = fields[item.date] || []
            field.transactions = fields[item.date]?.transactions || []
            field.transactions.push(item)
            fields[item.date] = field
            return fields
          },
          {}
        )

        for (const [key, value] of Object.entries(groupedFilteredTransactions) as any) {
          const val = value.transactions
            .map((item: any) => item.amount.amount)
            .reduce((prev: any, next: any) => prev + next)
          groupedFilteredTransactions[key].combined = val
        }

        setTransactions(Object.entries(groupedFilteredTransactions))
        setLoading(false)
      }
      fetchTransactions()
    }
  }, [property])

  return (
    <>
      <h2 className={styles.title}>POS-Transaktionen der letzten Woche</h2>
      <div className={styles.transactions}>
        {loading ? (
          <SkeletonTransaction />
        ) : errors.length > 0 ? (
          errors.map((error: string, i: number) => {
            return (
              <Box type="error" key={i}>
                {error}
              </Box>
            )
          })
        ) : (
          transactions.map((transaction: any, i: number) => {
            return <POSGroup transaction={transaction} key={i} />
          })
        )}
      </div>
    </>
  )
}

export default Properties
