import { useState, useEffect, useContext, useRef } from 'react'
import styles from './styles.module.scss'
import ApaleoService from 'services/ApaleoService'
import Group from './Group'
import SkeletonTransaction from './Transaction/Skeleton'
import Box from 'components/Box'
import dayjs from 'dayjs'
import { AuthContext } from 'context'

const Properties = () => {
  const { property } = useContext(AuthContext)
  const [loading, setLoading] = useState(true)
  const [errors, setErrors] = useState<string[]>([])
  const [activeTab, setActiveTab] = useState(0)
  const [transactions, setTransactions] = useState<unknown[]>([])
  const tabList = useRef<HTMLUListElement>(null)

  const goToTab = (i: number, e: any) => {
    setActiveTab(i)

    if (tabList.current) {
      tabList.current.scrollTo({
        left: e.target.offsetLeft - 1,
        behavior: 'smooth'
      })
    }
  }

  useEffect(() => {
    if (property) {
      const fetchTransactions = async () => {
        setLoading(true)
        const from = dayjs().subtract(1, 'M').toISOString()
        const to = dayjs().toISOString()
        const { data } = await ApaleoService.getTransactions(property, 'Payments', from, to)

        if (!data.transactions) {
          setErrors(['Keine Transaktionen gefunden'])
          setLoading(false)
          return
        }

        const groupedTransactions = data.transactions.reduce((fields: any, transaction: any) => {
          const accNumber = transaction.debitedAccount.number
          const isVisaCard = accNumber === '1102' || accNumber === '1103'
          const isMasterCard = accNumber === '1104' || accNumber === '1105'
          const isGiroCard = accNumber === '1107'
          const isVoucherPayment = accNumber === '1116'
          const isBankTransfer = accNumber === '1400'
          const isCashPayment = accNumber === '1600'

          const field = fields.all || {}
          field.name = 'Alle'
          field.order = 1
          field.transactions = fields.all?.transactions || []
          field.transactions.push(transaction)
          fields.all = field

          if (isCashPayment) {
            const field = fields.cashPayment || {}
            field.name = 'Cash Payment'
            field.order = 2
            field.transactions = fields.cashPayment?.transactions || []
            field.transactions.push(transaction)
            fields.cashPayment = field
          }

          if (isGiroCard) {
            const field = fields.giroCard || {}
            field.name = 'girocard'
            field.order = 3
            field.transactions = fields.giroCard?.transactions || []
            field.transactions.push(transaction)
            fields.giroCard = field
          }

          if (isVisaCard) {
            const field = fields.visaCard || {}
            field.name = 'Visa'
            field.order = 4
            field.transactions = fields.visaCard?.transactions || []
            field.transactions.push(transaction)
            fields.visaCard = field
          }

          if (isMasterCard) {
            const field = fields.masterCard || {}
            field.name = 'Mastercard'
            field.order = 5
            field.transactions = fields.masterCard?.transactions || []
            field.transactions.push(transaction)
            fields.masterCard = field
          }

          if (isBankTransfer) {
            const field = fields.bankTransfer || {}
            field.name = 'Bank Transfer'
            field.order = 6
            field.transactions = fields.bankTransfer?.transactions || []
            field.transactions.push(transaction)
            fields.bankTransfer = field
          }

          if (isVoucherPayment) {
            const field = fields.voucherPayment || {}
            field.name = 'Gutscheine'
            field.order = 7
            field.transactions = fields.voucherPayment?.transactions || []
            field.transactions.push(transaction)
            fields.voucherPayment = field
          }

          return fields
        }, {})

        for (const [key, value] of Object.entries(groupedTransactions) as any) {
          const transactionsByDate = value.transactions.reduce((fields: any, item: any) => {
            const field = fields[item.date] || []
            field.transactions = fields[item.date]?.transactions || []
            field.transactions.push(item)
            fields[item.date] = field
            return fields
          }, {})
          groupedTransactions[key].transactions = transactionsByDate

          for (const [dateKey, day] of Object.entries(value.transactions) as any) {
            const val = day.transactions
              .map((item: any) => item.amount.amount)
              .reduce((prev: any, next: any) => prev + next)
            value.transactions[dateKey].combined = val
          }
        }

        const sortedTransactions = Object.values(groupedTransactions).sort(function (
          a: any,
          b: any
        ) {
          return a.order - b.order
        })

        setTransactions(sortedTransactions)
        setLoading(false)
      }
      fetchTransactions()
    }
  }, [property])

  return (
    <>
      <h2 className={styles.title}>Transaktionen des letzten Monats</h2>
      <div className={styles.tabs}>
        <ul ref={tabList}>
          {!loading &&
            transactions.map((group: any, i) => {
              return (
                <li
                  onClick={(e) => goToTab(i, e)}
                  key={i}
                  className={activeTab === i ? styles.active : ''}
                >
                  <button>{group.name}</button>
                </li>
              )
            })}
        </ul>
      </div>
      <div className={styles.transactions}>
        {loading ? (
          <SkeletonTransaction />
        ) : errors.length > 0 ? (
          errors.map((error: string, i: number) => {
            return (
              <Box type="error" key={i}>
                {error}
              </Box>
            )
          })
        ) : (
          transactions.map((group: any, i) => {
            return (
              activeTab === i &&
              Object.entries(group.transactions).map((transaction: any, i: number) => {
                return <Group transaction={transaction} key={i} />
              })
            )
          })
        )}
      </div>
    </>
  )
}

export default Properties
