import dayjs from 'dayjs'
import { CustomReservation } from 'components/VisitorsCards/types'

const TaxGroups = {
  Leisure: '01',
  Children: '02',
  Business: '03'
} as const

const getTaxGroup = (reservation: CustomReservation, birthDate?: Date) => {
  const taxPattern = /TAX\d\d/gm
  const taxGroupFromComment = reservation.comment?.match(taxPattern)?.pop()?.split('TAX')[1]
  const age = dayjs(reservation.arrival).diff(dayjs(birthDate), 'y')

  if (birthDate && age < 18) {
    return TaxGroups.Children
  }

  if (taxGroupFromComment) {
    return taxGroupFromComment
  }

  if (reservation.travelPurpose === 'Business') {
    return TaxGroups.Business
  }

  return TaxGroups.Leisure
}

export const transformToFeratel = (reservations: CustomReservation[], landlordId: string) => {
  return reservations.map((reservation) => {
    const dateFormat = 'DD.MM.YYYY'

    const primaryGuest = {
      VermieterID: landlordId,
      Name: reservation.primaryGuest.lastName,
      Vorname: reservation.primaryGuest.firstName,
      Geburtsdatum: reservation.primaryGuest.birthDate
        ? dayjs(reservation.primaryGuest.birthDate).format(dateFormat)
        : '',
      Land: reservation.primaryGuest.address?.countryCode,
      Ort: reservation.primaryGuest.address?.city,
      Plz: reservation.primaryGuest.address?.postalCode,
      Strasse: reservation.primaryGuest.address?.addressLine1,
      Anreise: dayjs(reservation.arrival).format(dateFormat),
      Abreise: dayjs(reservation.departure).format(dateFormat),
      Personengruppe: getTaxGroup(reservation, reservation.primaryGuest.birthDate),
      Email: reservation.primaryGuest.email
    }

    // const additionalGuests = reservation.additionalGuests?.map((guest) => {
    //   return {
    //     VermieterID: landlordId,
    //     Name: guest.lastName,
    //     Vorname: guest.firstName,
    //     Geburtsdatum: guest.birthDate ? dayjs(guest.birthDate).format(dateFormat) : '',
    //     Land: guest.address?.countryCode,
    //     Ort: guest.address?.city,
    //     Plz: guest.address?.postalCode,
    //     Strasse: guest.address?.addressLine1,
    //     Anreise: dayjs(reservation.arrival).format(dateFormat),
    //     Abreise: dayjs(reservation.departure).format(dateFormat),
    //     Personengruppe: getTaxGroup(reservation, guest.birthDate),
    //     Email: guest.email
    //   }
    // })

    // const guests = [primaryGuest]

    // if (additionalGuests?.length) {
    //   guests.push(...additionalGuests)
    // }

    const guests = []

    for (let i = 0; i < reservation.adults; i++) {
      guests.push(primaryGuest)
    }

    reservation.childrenAges?.forEach(() => {
      guests.push({
        ...primaryGuest,
        Personengruppe: TaxGroups.Children
      })
    })

    return guests
  })
}

export const transformToTable = (reservations: CustomReservation[], landlordId: string) => {
  return reservations.map((reservation) => {
    const primaryGuest = {
      Anrede: reservation.primaryGuest.title,
      Vorname: reservation.primaryGuest.firstName,
      Nachname: reservation.primaryGuest.lastName,
      Email: reservation.primaryGuest.email,
      Ankunft: dayjs(reservation.arrival).format('DD.MM.YYYY'),
      Abreise: dayjs(reservation.departure).format('DD.MM.YYYY'),
      Straße: reservation.primaryGuest.address?.addressLine1,
      PLZ: reservation.primaryGuest.address?.postalCode,
      Ort: reservation.primaryGuest.address?.city,
      Land: reservation.primaryGuest.address?.countryCode,
      Erwachsene: reservation.adults,
      Kinder: reservation.childrenAges?.length ?? 0,
      Gasttyp: reservation.travelPurpose,
      Personengruppe: getTaxGroup(reservation, reservation.primaryGuest.birthDate),
      VermieterID: landlordId
    }

    // const additionalGuests = reservation.additionalGuests?.map((guest) => {
    //   return {
    //     Anrede: guest.title,
    //     Vorname: guest.firstName,
    //     Nachname: guest.lastName,
    //     Email: guest.email,
    //     Ankunft: dayjs(reservation.arrival).format('DD.MM.YYYY'),
    //     Abreise: dayjs(reservation.departure).format('DD.MM.YYYY'),
    //     Straße: guest.address?.addressLine1,
    //     PLZ: guest.address?.postalCode,
    //     Ort: guest.address?.city,
    //     Land: guest.address?.countryCode,
    //     Erwachsene: reservation.adults,
    //     Kinder: reservation.childrenAges?.length ?? 0,
    //     Gasttyp: reservation.travelPurpose,
    //     Personengruppe: getTaxGroup(reservation, guest.birthDate),
    //     VermieterID: landlordId
    //   }
    // })

    // const guests = [primaryGuest]

    // if (additionalGuests?.length) {
    //   guests.push(...additionalGuests)
    // }

    const guests = []

    for (let i = 0; i < reservation.adults; i++) {
      guests.push(primaryGuest)
    }

    reservation.childrenAges?.forEach(() => {
      guests.push({
        ...primaryGuest,
        Personengruppe: TaxGroups.Children
      })
    })

    return guests
  })
}

/* Feratel XML Format */
// PMSImport: {
//   gemeinde: {
//     oestat: 'Official visitor registration number of the community/municipality',
//     version: '1.0, 2.0 or 5',
//     betrieb: {
//       betriebnr: landlordId,
//       meldeblatt: {
//         mblattnr: 1,
//         bearbeiter: 'Haffhus GmbH',
//         ankunft: dayjs(reservation.arrival).format(dateFormat),
//         abgeplant: dayjs(reservation.arrival).format(dateFormat),
//         landschl: {
//           lschlnr: reservation.primaryGuest.address?.countryCode,
//           anzpers: reservation.adults
//         },
//         gastart: {
//           gastart: taxGroup
//             ? taxGroup
//             : reservation.travelPurpose === 'Leisure'
//             ? '01'
//             : '03',
//           anzpers: reservation.adults
//         },
//         gast: [
//           {
//             gastlfdnr: 1,
//             gasttyp: 'HG',
//             anrede: reservation.primaryGuest.title,
//             vorname: reservation.primaryGuest.firstName,
//             name: reservation.primaryGuest.lastName,
//             strasse: reservation.primaryGuest.address?.addressLine1,
//             nation: reservation.primaryGuest.address?.countryCode,
//             plz: reservation.primaryGuest.address?.postalCode,
//             ort: reservation.primaryGuest.address?.city,
//             gebdatum: reservation.primaryGuest.birthDate
//               ? dayjs(reservation.primaryGuest.birthDate).format(dateFormat)
//               : '',
//             email: reservation.primaryGuest.email
//           }
//         ]
//       }
//     }
//   },
//   companyCode: landlordId
// }
