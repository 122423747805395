import './App.scss'
import 'react-loading-skeleton/dist/skeleton.css'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { AuthProvider, GlobalProvider } from 'context'
import ProtectedRoute from 'components/ProtectedRoute'
import Layout from 'components/Layout'
import Dashboard from 'components/Dashboard'
import AuthCallback from 'components/AuthCallback'
import Login from 'components/Login'
import dayjs from 'dayjs'
import 'dayjs/locale/de'
dayjs.locale('de')

function App() {
  return (
    <AuthProvider>
      <GlobalProvider>
        <Router>
          <Layout>
            <Switch>
              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>
              <ProtectedRoute exact path="/dashboard" component={Dashboard} />
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/oauth/callback">
                <AuthCallback />
              </Route>
            </Switch>
          </Layout>
        </Router>
      </GlobalProvider>
    </AuthProvider>
  )
}

export default App
