import styles from './styles.module.scss'
import { ReactComponent as SelectIcon } from 'assets/icons/select.svg'

interface IProps {
  children: React.ReactChild | React.ReactChild[] | React.ReactChildren | React.ReactChildren[]
  value?: string
  defaultValue?: string
  onChange?: (e: any) => void
  className?: string
}

const Select = (props: IProps) => {
  return (
    <div className={`${styles.select} ${props.className ? props.className : ''}`}>
      <select value={props.value} defaultValue={props.defaultValue} onChange={props.onChange}>
        {props.children}
      </select>
      <span className={styles.icon}>
        <SelectIcon />
      </span>
    </div>
  )
}

export default Select
