import React, { useState } from 'react'
import styles from './styles.module.scss'
import Button from 'components/Button'
import { ReactComponent as ApaleoIcon } from 'assets/icons/apaleo.svg'
import ApaleoService from 'services/ApaleoService'

const Login = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const login = async () => {
    setLoading(true)
    await ApaleoService.login()
    setLoading(false)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <h1>
          Login via <span style={{ textDecoration: 'underline' }}>apaleo</span>
        </h1>
        <p>
          Um auf das Dashboard zugreifen zu können, müsstest Du Dich zunächst mit Deinem apaleo
          Account verbinden.
        </p>
        <div className={styles.providers}>
          <Button
            text="Mit apaleo anmelden"
            prefix={<ApaleoIcon />}
            style={{ textTransform: 'uppercase', fontWeight: 600, height: '44px' }}
            loading={loading}
            onClick={login}
          />
        </div>
      </div>
    </div>
  )
}

export default Login
