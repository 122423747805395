import React, { useContext } from 'react'
import { AuthContext } from 'context'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom'

const ProtectedRoute = ({ component: Component, ...rest }: RouteProps) => {
  const { isAuthenticated } = useContext(AuthContext)

  if (!Component) {
    return null
  }

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => (
        isAuthenticated ? (
          <Component {...props} />
        ) : (
            <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
          )
      )}
    />
  )
}

export default ProtectedRoute
