import ApaleoAPI from 'services/ApaleoAPI'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ApaleoReservationResponse } from 'services/types'

type Credentials = {
  access_token: string
  refresh_token: string
  id_token?: string
  expires_in?: number
  scope?: string
  token_type?: string
}

const STORAGE_KEY = 'apaleoCredentials'
const REFRESH_URL = '/api/refresh'

export default class ApaleoService {
  static login = () => {
    return new Promise<any>((resolve, reject) => {
      ;(async () => {
        try {
          const res = await axios.get('/api/url')
          const loginURL = res.data.url
          resolve((window.location.href = loginURL))
        } catch (error) {
          reject(error)
        }
      })()
    })
  }

  static logout = () => {
    localStorage.removeItem(STORAGE_KEY)
    window.location.reload()
  }

  static storeCredentials = (credentials: Credentials) => {
    return new Promise<any>((resolve, reject) => {
      if (credentials.access_token && credentials.refresh_token) {
        const apaleoCredentials = {
          access_token: credentials.access_token,
          refresh_token: credentials.refresh_token,
          expires_in: credentials.expires_in ?? 0,
          scope: credentials.scope ?? '',
          token_type: credentials.token_type ?? 'Bearer'
        }

        /* Store Access Token + Information in the local storage */
        localStorage.setItem(STORAGE_KEY, JSON.stringify(apaleoCredentials))
        resolve('Successfully stored credentials')
      } else {
        reject(new Error('Provided credentials to store are invalid'))
      }
    })
  }

  static refreshAccessToken = async () => {
    return new Promise<any>((resolve, reject) => {
      ;(async () => {
        try {
          const refresh_token = await ApaleoService.getRefreshToken()
          const params = {
            refresh_token
          }

          const config: AxiosRequestConfig = {
            method: 'GET',
            url: REFRESH_URL,
            params
          }

          const { data: newCredentials } = await axios(config)
          await ApaleoService.storeCredentials(newCredentials)
          resolve(newCredentials)
        } catch (error) {
          console.log('Refresh Token revoked. Logging out...')
          ApaleoService.logout()
          reject(error)
        }
      })()
    })
  }

  static getAccessToken = async () => {
    const credentials = localStorage.getItem(STORAGE_KEY)
    if (credentials) {
      const token = JSON.parse(credentials)?.access_token
      return token
    } else {
      const credentials = await ApaleoService.login()
      return credentials.access_token
    }
  }

  static getRefreshToken = async () => {
    // const cookies = new Cookies()
    // const refresh_token = cookies.get('X-Apaleo-Refresh-Token')
    // return refresh_token
    const credentials = localStorage.getItem(STORAGE_KEY)
    if (credentials) {
      const token = JSON.parse(credentials)?.refresh_token
      return token
    } else {
      const credentials = await ApaleoService.login()
      return credentials.refresh_token
    }
  }

  static hasCredentials = () => {
    return Boolean(localStorage.getItem(STORAGE_KEY))
  }

  static getProperties = async () => {
    try {
      const { data: properties } = await ApaleoAPI.get(
        'https://api.apaleo.com/inventory/v1/properties'
      )
      return properties.properties
    } catch (error) {
      throw error
    }
  }

  static getUser = async () => {
    try {
      return await ApaleoAPI.get('https://api.apaleo.com/account/v1/accounts/current')
    } catch (error) {
      throw error
    }
  }

  static getTransactions = async (
    propertyId: string,
    accountType: string,
    from: string,
    to: string,
    options?: { [key: string]: string }
  ) => {
    try {
      const params = new URLSearchParams()
      params.append('propertyId', propertyId)
      params.append('accountType', accountType)
      params.append('languageCode', 'de')
      params.append('from', from.replace(/.\d+Z$/g, 'Z'))
      params.append('to', to.replace(/.\d+Z$/g, 'Z'))

      if (options) {
        for (const [key, value] of Object.entries(options)) {
          params.append(key, value)
        }
      }

      const config: AxiosRequestConfig = {
        method: 'POST',
        url: 'https://api.apaleo.com/finance/v1/accounts/export',
        params
      }

      return await ApaleoAPI(config)
    } catch (error) {
      throw error
    }
  }

  static getReservations = async (
    propertyId: string,
    dateFilter: string,
    from: string,
    to: string,
    options?: Record<string, any>
  ) => {
    try {
      const params = new URLSearchParams()
      params.append('propertyId', propertyId)
      params.append('dateFilter', dateFilter)
      params.append('from', from.replace(/.\d+Z$/g, 'Z'))
      params.append('to', to.replace(/.\d+Z$/g, 'Z'))

      if (options) {
        for (const [key, value] of Object.entries(options)) {
          params.append(key, value)
        }
      }

      const config: AxiosRequestConfig = {
        method: 'GET',
        url: 'https://api.apaleo.com/booking/v1/reservations',
        params
      }

      const res: AxiosResponse<ApaleoReservationResponse> = await ApaleoAPI(config)
      return res
    } catch (error) {
      throw error
    }
  }
}
