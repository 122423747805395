import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import ApaleoService from 'services/ApaleoService'

interface AxiosErrorRequestConfig extends AxiosRequestConfig {
  _retry?: boolean
}

/* Creating Axios Instance */
const ApaleoAPI = axios.create()

/* Request Interceptor */
ApaleoAPI.interceptors.request.use(
  async (config) => {
    const token = await ApaleoService.getAccessToken()
    config.headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

/* Response Interceptor */
ApaleoAPI.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as AxiosErrorRequestConfig
    console.error(error)
    if (
      (error?.response?.status === 401 || error?.response?.status === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true
      console.warn(`Request has failed (${error.response.status}). Refreshing access token...`)
      const newCredentials = await ApaleoService.refreshAccessToken()
      const token = newCredentials.access_token
      originalRequest.headers['Authorization'] = `Bearer ${token}`
      return ApaleoAPI(originalRequest)
    }
    return Promise.reject(error)
  }
)

export default ApaleoAPI
