import React from 'react'
import styles from './styles.module.scss'
import Header from 'components/Header'

type LayoutProps = {
  children: React.ReactNode
}

const Layout = (props: LayoutProps) => {
  return (
    <>
      <Header />

      <div className={styles.wrapper}>{props.children}</div>
    </>
  )
}

export default Layout
