import React, { createContext, useReducer } from 'react'
import ApaleoService from 'services/ApaleoService'
import { ACTIONS } from './actions'
import AuthReducer from './reducer'

const isAuth = ApaleoService.hasCredentials()
const initialProperty = localStorage.getItem('property') || ''

type InitialState = {
  isAuthenticated: boolean
  setIsAuthenticated: (isAuth: boolean) => void
  property: string
  setProperty: (property: string) => void
}

/* Initial State */
export const initialAuthState: InitialState = {
  isAuthenticated: isAuth,
  setIsAuthenticated: () => null,
  property: initialProperty,
  setProperty: () => null
}

/* Context */
export const AuthContext = createContext<InitialState>(initialAuthState)

/* Provider */
export const AuthProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialAuthState)

  function setIsAuthenticated(isAuth: boolean) {
    dispatch({
      type: ACTIONS.SET_IS_AUTHENTICATED,
      payload: isAuth
    })
  }

  function setProperty(property: string) {
    dispatch({
      type: ACTIONS.SET_PROPERTY,
      payload: property
    })
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        setIsAuthenticated,
        property: state.property,
        setProperty
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
