import { useState } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as MasterCardIcon } from 'assets/icons/mastercard.svg'
import { ReactComponent as VisaCardIcon } from 'assets/icons/visacard.svg'
import { ReactComponent as GiroCardIcon } from 'assets/icons/girocard.svg'
import { ReactComponent as OtherIcon } from 'assets/icons/coin.svg'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

type Props = {
  transaction: any
}

const Transaction = ({ transaction }: Props) => {
  const accNumber = transaction.debitedAccount.number
  const isVisaCard = accNumber === '1102'
  const isMasterCard = accNumber === '1104'
  const isGiroCard = accNumber === '1107'
  const isOther = !isGiroCard && !isMasterCard && !isVisaCard

  const transactionTime = `${dayjs(transaction.timestamp).format('DD. MMMM YYYY')}, ${dayjs(
    transaction.timestamp
  ).format('HH:mm')} Uhr`

  const [showDetails, setShowDetails] = useState<boolean>(false)

  return (
    <div className={styles.transaction}>
      <div className={styles.header} onClick={() => setShowDetails(!showDetails)}>
        <span className={styles.icon}>
          {isVisaCard && <VisaCardIcon />}
          {isMasterCard && <MasterCardIcon />}
          {isGiroCard && <GiroCardIcon />}
          {isOther && <OtherIcon />}
        </span>
        <span className={styles.name}>{transaction.reference}</span>
        <span className={styles.amount}>
          {transaction.amount.currency.replace(/EUR/, '€')} {transaction.amount.amount.toFixed(2)}
        </span>
        <span className={styles.time} title={transactionTime}>
          {dayjs(transaction.timestamp).fromNow()}
        </span>
      </div>

      {showDetails && (
        <div className={styles.details}>
          <div className={styles.referenceNumber}>
            <span>Zahlungsart:</span>
            <span>{transaction.debitedAccount.name}</span>
          </div>
          <div className={styles.referenceType}>
            <span>Referenz-Typ:</span>
            <span>{transaction.referenceType}</span>
          </div>
          <div className={styles.dateTime}>
            <span>Datum:</span>
            <span className={styles.date}>{transactionTime}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default Transaction
