import * as React from 'react'
import styles from './styles.module.scss'

export interface ToggleProps {
  checked: boolean
  onChange: (checked: boolean) => void
  label?: string
}

const Toggle = (props: ToggleProps) => {
  const { checked, label, onChange } = props

  return (
    <div>
      <label className={styles.container}>
        <div className={[styles.toggle, checked && styles.active].filter(Boolean).join(' ')}>
          <span></span>
        </div>
        {label && (
          <div className={styles.label}>
            <span>{label}</span>
          </div>
        )}

        {/* Hidden Input */}
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          className={styles.input}
        />
      </label>
    </div>
  )
}

export default Toggle
