import styles from './styles.module.scss'
import Transactions from 'components/Transactions'
import POSTransactions from 'components/POSTransactions'
import VisitorsCards from 'components/VisitorsCards'
import Tabs from 'components/Tabs'
import { useContext } from 'react'
import { GlobalContext } from 'context'

const Dashboard = () => {
  const { activeTab } = useContext(GlobalContext)
  const tabs = [<Transactions />, <POSTransactions />, <VisitorsCards />]

  return (
    <div className={styles.dashboard}>
      <Tabs />
      {tabs[activeTab] || 'This tab is not defined'}
    </div>
  )
}

export default Dashboard
