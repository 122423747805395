import React from 'react'
import styles from './styles.module.scss'

interface IProps {
  html?: string
  type?: 'success' | 'error'
  style?: React.CSSProperties
  children?: React.ReactChild | React.ReactChild[] | React.ReactChildren | React.ReactChildren[]
}

const Box = (props: IProps) => {
  return (
    <>
      {props.html && props.type ? (
        <div
          dangerouslySetInnerHTML={{ __html: props.html }}
          className={styles[props.type]}
          style={props.style}
        ></div>
      ) : props.type ? (
        <div className={styles[props.type]} style={props.style}>
          {props.children}
        </div>
      ) : (
        <div className={styles.box} style={props.style}>
          {props.children}
        </div>
      )}
    </>
  )
}

export default Box
