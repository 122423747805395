import { ACTIONS } from './actions'
import { initialAuthState } from '.'

const AuthReducer = (state: typeof initialAuthState, action: any) => {
  switch (action.type) {
    case ACTIONS.SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload
      }

    case ACTIONS.SET_PROPERTY:
      localStorage.setItem('property', action.payload)
      return {
        ...state,
        property: action.payload
      }

    default:
      return state
  }
}

export default AuthReducer
