import { ACTIONS } from './actions'
import { initialGlobalState } from '.'

const AuthReducer = (state: typeof initialGlobalState, action: any) => {
  switch (action.type) {
    case ACTIONS.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload
      }

    default:
      return state
  }
}

export default AuthReducer
