import Loader from 'components/Loader'
import React from 'react'
import styles from './styles.module.scss'

interface GeneralProps {
  loading?: boolean
  disabled?: boolean
  type?: 'secondary' | 'success' | 'error' | 'warning' | 'alert'
  variant?: 'shadow'
  title?: string
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  style?: React.CSSProperties
  className?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

interface WithText extends GeneralProps {
  text: string
  shape?: never
}
interface WithShape extends GeneralProps {
  shape: React.ReactNode
  text?: never
}

type ButtonProps = WithText | WithShape

const Button = (props: ButtonProps) => {
  return (
    <button
      disabled={props.disabled || props.loading}
      title={props.title || props.text}
      style={props.style}
      onClick={props.onClick}
      className={[
        styles.button,
        props.type && styles[props.type],
        props.variant && styles[props.variant],
        props.loading && styles.loading,
        props.className
      ]
        .filter(Boolean)
        .join(' ')}
    >
      {/* Prefix Icon */}
      {props.prefix && <span className={styles.prefix}>{props.prefix}</span>}

      <span className={styles.content}>{'shape' in props ? props.shape : props.text}</span>

      {/* Suffix Icon */}
      {props.suffix && <span className={styles.suffix}>{props.suffix}</span>}

      {props.loading && (
        <span className={styles.loader}>
          <Loader />
        </span>
      )}
    </button>
  )
}

export default Button
