import { useContext } from 'react'
import { GlobalContext } from 'context'
import styles from './styles.module.scss'

const Tabs = () => {
  const { activeTab, setActiveTab } = useContext(GlobalContext)
  const tabs = ['Transaktionen', 'POS-Transaktionen', 'Kurkarten']

  const goToTab = (index: number) => {
    setActiveTab(index)
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  const list = tabs.map((tab, i) => {
    return (
      <li key={i}>
        <button onClick={() => goToTab(i)} className={activeTab === i ? styles.active : ''}>
          {tab}
        </button>
      </li>
    )
  })

  return (
    <div className={styles.tabs}>
      <ul>{list}</ul>
    </div>
  )
}

export default Tabs
