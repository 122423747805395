import Skeleton from 'react-loading-skeleton'
import styles from './styles.module.scss'
import skeleton from './skeleton.module.scss'

const SkeletonTransaction = () => {
  return (
    <>
      {Array(7)
        .fill(null)
        .map((_, i) => {
          return (
            <div className={styles.transaction} key={i}>
              <div className={styles.header}>
                <span className={[styles.name, skeleton.name].join(' ')}>
                  <Skeleton width={260} />
                </span>
                <span className={styles.amount}>
                  <Skeleton width={50} />
                </span>
                <span className={styles.time}>
                  <Skeleton width={80} />
                </span>
              </div>
            </div>
          )
        })}
    </>
  )
}

export default SkeletonTransaction
