import { useState } from 'react'
import styles from './styles.module.scss'
import Transaction from '../Transaction'
import dayjs from 'dayjs'

interface IProps {
  transaction: any
}

const POSGroup = ({ transaction }: IProps) => {
  const [showDetails, setShowDetails] = useState(false)

  const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  })

  return (
    <div className={styles.group}>
      <div className={styles.tab} onClick={() => setShowDetails(!showDetails)}>
        <span className={styles.date}>{dayjs(transaction[0]).format('dddd, DD.MM.YYYY')}</span>
        <span className={styles.amount}>{formatter.format(transaction[1].combined)}</span>
      </div>
      {showDetails && (
        <div className={styles.inner}>
          {transaction[1].transactions.map((transaction: any, i: number) => {
            return <Transaction transaction={transaction} key={i} />
          })}
        </div>
      )}
    </div>
  )
}

export default POSGroup
