import React, { createContext, useReducer } from 'react'
import { ACTIONS } from './actions'
import GlobalReducer from './reducer'

type InitialState = {
  activeTab: number
  setActiveTab: (tabIndex: number) => void
}

/* Initial State */
export const initialGlobalState: InitialState = {
  activeTab: 0,
  setActiveTab: () => null
}

/* Context */
export const GlobalContext = createContext<InitialState>(initialGlobalState)

/* Provider */
export const GlobalProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(GlobalReducer, initialGlobalState)

  function setActiveTab(tabIndex: number) {
    dispatch({
      type: ACTIONS.SET_ACTIVE_TAB,
      payload: tabIndex
    })
  }

  return (
    <GlobalContext.Provider
      value={{
        activeTab: state.activeTab,
        setActiveTab
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
