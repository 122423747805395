import React, { useContext, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { AuthContext } from 'context'
import axios, { AxiosRequestConfig } from 'axios'
import styles from './styles.module.scss'
import ApaleoService from 'services/ApaleoService'
import Loader from 'components/Loader'

type CallbackParams = {
  code?: string
  state?: string
  session_state?: string
}

const AuthCallback = () => {
  let location = useLocation()
  let history = useHistory()

  const { setIsAuthenticated } = useContext(AuthContext)

  const CODE_TO_TOKEN_URL = '/api/code'

  const urlParams = new URLSearchParams(location.search)
  const callbackParams = Object.fromEntries(urlParams)

  const exchangeCodeForToken = async (callbackParams: CallbackParams) => {
    try {
      const params = {
        code: callbackParams.code,
        state: callbackParams.state,
        session_state: callbackParams.session_state
      }

      const config: AxiosRequestConfig = {
        method: 'GET',
        url: CODE_TO_TOKEN_URL,
        params
      }

      const res = await axios(config)
      const credentials = res.data
      const access_token = credentials.access_token
      const refresh_token = credentials.refresh_token

      if (access_token && refresh_token) {
        await ApaleoService.storeCredentials(credentials)
        setIsAuthenticated(true)
        history.push('/')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    exchangeCodeForToken(callbackParams)
  }, [])

  return (
    <div className={styles.wrapper}>
      <Loader size="large" />
    </div>
  )
}

export default AuthCallback
