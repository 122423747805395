import { useContext, useState, useEffect } from 'react'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'
import ApaleoService from 'services/ApaleoService'
import Button from 'components/Button'
import Select from 'components/Select'
import { AuthContext } from 'context'

const Header = () => {
  const { isAuthenticated, property, setProperty } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [properties, setProperties] = useState([])

  const login = async () => {
    setLoading(true)
    await ApaleoService.login()
    setLoading(false)
  }

  const logout = () => {
    setLoading(true)
    ApaleoService.logout()
    setLoading(false)
  }

  const getProperties = async () => {
    const properties = await ApaleoService.getProperties()
    const property = localStorage.getItem('property')
    if (property) {
      setProperty(property)
    } else {
      setProperty(properties[0].id)
    }
    setProperties(properties)
  }

  const updateProperty = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setProperty(e.target.value)
    window.scroll({
      top: 0,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    if (isAuthenticated) {
      getProperties()
    }
  }, [isAuthenticated])

  return (
    <div className={styles.header}>
      <div className={styles.inner}>
        <Link to="/" className={styles.title}>
          Dashboard
        </Link>

        {isAuthenticated && (
          <Select className={styles.select} value={property} onChange={(e) => updateProperty(e)}>
            {properties.map((property: any, i) => {
              return (
                <option value={property.id} key={i}>
                  {property.id}
                </option>
              )
            })}
          </Select>
        )}

        {isAuthenticated ? (
          <Button text="Logout" loading={loading} onClick={logout} />
        ) : (
          <Button text="Login" loading={loading} onClick={login} />
        )}
      </div>
    </div>
  )
}

export default Header
