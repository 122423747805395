import { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from 'context'
import styles from './styles.module.scss'
import ApaleoService from 'services/ApaleoService'
import Button from 'components/Button'
import dayjs from 'dayjs'
import { XLSX$Utils } from 'xlsx'
import { DayPicker, DateRange } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import de from 'date-fns/locale/de'
import Toggle from 'components/Toggle'
import { BiLinkExternal } from 'react-icons/bi'
import { CustomReservation } from './types'
import { transformToFeratel, transformToTable } from 'utils'

const VisitorsCards = () => {
  const { property } = useContext(AuthContext)
  const [landlordId, setLandlordId] = useState('77000002')
  const [reservations, setReservations] = useState<CustomReservation[]>([])
  const [isOnlyDepartures, setIsOnlyDepartures] = useState(true)
  const [isFeratelOutput, setIsFeratelOutput] = useState(true)
  const [loading, setLoading] = useState(false)

  const pickerRef = useRef<HTMLDivElement>(null)
  const lastMonth = dayjs().subtract(1, 'M')
  const [showPicker, setShowPicker] = useState(false)
  const [range, setRange] = useState<DateRange | undefined>({
    from: lastMonth.startOf('M').toDate(),
    to: lastMonth.endOf('M').toDate()
  })

  let xlsxUtils: XLSX$Utils | null = null
  let xlsxWriteFile: any = null

  const getReservations = async () => {
    setLoading(true)
    try {
      if (!range?.from || !range?.to) return
      const res = await ApaleoService.getReservations(
        property,
        isOnlyDepartures ? 'Departure' : 'Stay',
        range.from.toISOString(),
        range.to.toISOString(),
        {
          status: ['InHouse', 'Confirmed', 'CheckedOut']
        }
      )

      const reservations: CustomReservation[] = res?.data?.reservations?.map((reservation) => {
        return {
          ...reservation,
          export: true
        }
      })

      if (!reservations) {
        setReservations([])
        return
      }

      setReservations(reservations)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const exportAsExcelFile = async (format = 'xlsx' as 'xlsx' | 'csv') => {
    setLoading(true)
    try {
      if (!xlsxUtils || !xlsxWriteFile) {
        const { utils, writeFile } = await import('xlsx')
        xlsxUtils = utils
        xlsxWriteFile = writeFile
      }

      let filteredReservations = reservations.filter((reservation) => reservation.export)
      let transformedReservations: any[] = []

      if (isFeratelOutput) {
        transformedReservations = transformToFeratel(filteredReservations, landlordId).flat()
      } else {
        transformedReservations = transformToTable(filteredReservations, landlordId).flat()
      }

      const ws = xlsxUtils.json_to_sheet(transformedReservations)
      const wb = xlsxUtils.book_new()
      xlsxUtils.book_append_sheet(wb, ws)

      // const bin = write(wb, { type: 'binary', bookType: 'xlsx' })
      // const blob = new Blob([Buffer.from(bin)], { type: 'application/octet-stream' })
      // const url = URL.createObjectURL(blob)
      // window.open(url)

      const fileName = range?.to
        ? `Kurkarten_${dayjs(range.from).format('YYYY_MM_DD')}-${dayjs(range.to).format(
            'YYYY_MM_DD'
          )}.${format}`
        : `Kurkarten.${format}`
      const bin = xlsxWriteFile(wb, fileName, { bookType: format })
      return bin
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const toggleExport = (i: number) => {
    const newReservations = [...reservations]
    newReservations[i].export = !newReservations[i].export
    setReservations(newReservations)
  }

  const reservationList = reservations.map((reservation, i) => {
    return (
      <li key={reservation.id}>
        <div className={styles.reservationContainer} onClick={() => toggleExport(i)}>
          <div className={styles.reservation}>
            <span className={styles.id}>{reservation.id}</span>
            <span className={styles.info}>
              (
              {`${reservation.primaryGuest.firstName} ${reservation.primaryGuest.lastName}, ${dayjs(
                reservation.arrival
              ).format('DD.MM.YYYY')} - ${dayjs(reservation.departure).format('DD.MM.YYYY')}`}
              )
            </span>
            <div className={styles.reservationActions}>
              <a
                href={`https://app.apaleo.com/${property}/reservations/${reservation.id}`}
                target="_blank"
                rel="noreferrer"
                onClick={(e) => e.stopPropagation()}
                className={styles.link}
              >
                <BiLinkExternal />
              </a>
              <span
                className={[styles.indicator, reservation.export && styles.export]
                  .filter(Boolean)
                  .join(' ')}
              ></span>
            </div>
          </div>

          {reservation.additionalGuests && (
            <ul className={styles.additionalGuests}>
              <span>Zusätzliche Gäste: </span>
              {reservation.additionalGuests.map((guest, i) => {
                return (
                  <span key={`${reservation.id}_additionalGuest_${i}`}>
                    <span>
                      {guest.firstName} {guest.lastName}
                    </span>
                    {i !== reservation.additionalGuests!.length - 1 && ', '}
                  </span>
                )
              })}
            </ul>
          )}
        </div>
      </li>
    )
  })

  useEffect(() => {
    const onOutsidePickerClick = (e: MouseEvent) => {
      if (showPicker && pickerRef.current && !pickerRef.current.contains(e.target as Node)) {
        setShowPicker(false)
      }
    }

    document.addEventListener('mousedown', onOutsidePickerClick)

    return () => {
      document.removeEventListener('mousedown', onOutsidePickerClick)
    }
  }, [showPicker])

  return (
    <div className={styles.container}>
      <div className={styles.inputs}>
        <div className={styles.date}>
          <input
            type="text"
            readOnly
            value={`${dayjs(range?.from).format('DD.MM.YYYY')} – ${dayjs(range?.to).format(
              'DD.MM.YYYY'
            )}`}
            onFocus={() => setShowPicker(true)}
          />
          {showPicker && (
            <div ref={pickerRef} className={styles.picker}>
              <DayPicker
                mode="range"
                defaultMonth={lastMonth.toDate()}
                locale={de}
                selected={range}
                onSelect={setRange}
              />
            </div>
          )}
        </div>
        <div className={styles.config}>
          <div>
            <span>Nur Abreisen</span>
            <Toggle checked={isOnlyDepartures} onChange={setIsOnlyDepartures} />
          </div>
        </div>
        <div className={styles.config}>
          <div>
            <span>Feratel</span>
            <Toggle checked={isFeratelOutput} onChange={setIsFeratelOutput} />
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <Button
          text="Reservierungen generieren"
          onClick={getReservations}
          loading={loading}
          className={styles.button}
        />
        {reservations.length > 0 && (
          <div className={styles.exportActions}>
            <Button
              text="Export Excel"
              onClick={() => exportAsExcelFile('xlsx')}
              loading={loading}
              className={styles.button}
            />
            <Button
              text="Export CSV"
              onClick={() => exportAsExcelFile('csv')}
              loading={loading}
              className={styles.button}
            />
          </div>
        )}
      </div>
      {reservations.length > 0 && (
        <label className={styles.landlord}>
          <span>Vermieter ID</span>
          <input type="number" value={landlordId} onChange={(e) => setLandlordId(e.target.value)} />
        </label>
      )}
      <ul className={styles.reservations}>{reservationList}</ul>
    </div>
  )
}

export default VisitorsCards
